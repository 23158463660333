import './scripts/common';
import {onFind} from '@elements/init-modules-in-scope';

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};

import Tooltip from 'bootstrap/js/dist/tooltip';
onFind('[data-bs-toggle="tooltip"]', (element) => {
    new Tooltip(element);
});

// Todo: Add page specific JS here e.g:

import * as dynamicLightbox from './scripts/dynamic-lightbox';
dynamicLightbox.init();

import * as mixedCardTeaserSlider from './scripts/mixed-card-teaser-slider';
mixedCardTeaserSlider.init();

import * as imgTeaserRowSlider from './scripts/img-teaser-row-slider';
imgTeaserRowSlider.init();

import * as video from './scripts/video';
video.init();

import * as imageSlider from './scripts/image-slider';
imageSlider.init();

import * as formValidation from '@elements/form-validation';
formValidation.init();

import * as ticketCheckbox from './scripts/ticket-checkbox';
ticketCheckbox.init();

import {init} from '@elements/anchor-nav/bootstrap-5'
init({
        renderItem: function (text, anchor) {
            return '<li class="anchor-nav__item nav-item"><a class="nav-link js-smoothscroll__link" data-scroll-offset="250" href="#' + anchor + '">' + text + '</a></li>';
        },
        smoothScroll: true,
        scrollSpy: true,
        scrollSpyTarget: '.js-anchor-nav',
        scrollSpyOffset: 0,
    },
    {
        list: '.js-anchor-nav__list',
        item: '.js-anchor-nav__item'
    }
);

import * as smoothScroll from '@elements/smooth-scroll';
smoothScroll.init({scrollOffset: 1000});

import * as trackNewsletterForm from './scripts/tracking/newsletter-form';
trackNewsletterForm.init();

import * as trackContactForm from './scripts/tracking/contact-form';
trackContactForm.init();
