import {onFind} from "@elements/init-modules-in-scope";
import {removeClass, addClass, hasClass, findIn, trigger, findAllIn, closest, on} from '@elements/dom-utils';

export function init () {

    onFind('.js-ticket-checkbox', baseElement => {
        let checkbox = findIn('.js-ticket-checkbox__check', baseElement);
        let ticketCheckboxIcon = findIn('.js-ticket-checkbox__icon', baseElement);
        let ticketCheckboxLabel = findIn('.js-ticket-checkbox__label', baseElement);

        on('change', (e) => {
            if(checkbox.checked) {
                addClass('is-active', baseElement);
                addClass('is-active', ticketCheckboxIcon);
            } else {
                removeClass('is-active', baseElement);
                removeClass('is-active', ticketCheckboxIcon);
            }
        }, checkbox);

        on('click', (e) => {
            if(e.target !== checkbox && e.target !== ticketCheckboxLabel){
                checkbox.checked = !checkbox.checked;
                trigger('change', checkbox);
            }

        }, baseElement);

    });
}