import {onFind} from "@elements/init-modules-in-scope";
import BlazeSlider from 'blaze-slider';
import {addClass, find} from "@elements/dom-utils";

export function init() {
    onFind('.js-img-teaser-row-slider', function (baseElement) {
        //Workaround to set slidesToShow to given values as blaze-slider overwrites slidesToShow if number of items is less than slidesToShow
        const slidesToShow = 4;
        const slidesToShow767 = 1.2;
        let style = document.createElement('style');

        let slideToShowValue = slidesToShow;
        if(window.matchMedia('(max-width: 767px)').matches) {
            slideToShowValue = slidesToShow767;
        }

        style.id = 'img-teaser-row-slider';
        style.innerHTML = '.slidesToShow--imgTeaserRow { --slides-to-show: ' + slideToShowValue + ' !important; }';
        let head = find('head');
        if (!find('#' + style.id, head)) {
            head.appendChild(style);
        }

        new BlazeSlider(baseElement, {
            all: {
                loop: false,
                slidesToScroll: 1,
                slideGap: '8px',
                slidesToShow: slidesToShow,
                draggable: true,
            },
            '(max-width: 767px)': {
                slidesToShow: slidesToShow767
            },
        });
        addClass('slidesToShow--imgTeaserRow', baseElement);
    });
}