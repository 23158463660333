import { on, findAllIn } from '@elements/dom-utils';
import { onFind } from "@elements/init-modules-in-scope";
import { toHash } from "@elements/hashing-sha256";
import { getPrefixedDataSet } from "@elements/data-set-utils";

const defaultSelectors = {
    base: '.js-form-tracking-contact',
    input: '.js-form-tracking-contact__input',
};

const defaultOptions = {
    debug: false,
    formEvent: 'form-validation.submit'
};

export function init(selectors = defaultSelectors, options = defaultOptions) {
    onFind(selectors.base, function (form) {
        on(options.formEvent, () => {
            window.dataLayer.push({
                'event': 'contact_form_sent',
                'leadsUserData': getLeadsUserData(form, selectors)
            });

            if(options.debug){
                console.log('dataLayer: ', window.dataLayer);
            }
        }, form);
    });
}

/*leadsUserData Example
see: https://support.google.com/google-ads/answer/13262500?hl=de#zippy=%2Cvariablen-f%C3%BCr-erweiterte-conversions-ermitteln-und-festlegen

'leadsUserData': {
    'sha256_email_address': toHash(email),
    'sha256_phone_number': toHash(phoneNumber),
    'address': {
        sha265_first_name: toHash(firstname),
        sha256_last_name: toHash(lastname),
        street: streetAddress,
        city: 'Linz',
        region: 'Upper Austria',
        postal_code: '12345',
        country: 'de',
    }
}
*/

function getLeadsUserData(form, selectors) {
    let inputs = findAllIn(selectors.input, form);
    let leadsUserData = {};

    inputs.map(input => {
        let dataset = getPrefixedDataSet( 'form-tracking', input);

        if (dataset.attribute && (typeof dataset.attribute === 'string')) {
            if (dataset.attribute.includes('sha256_')) {
                leadsUserData[dataset.attribute] = toHash(input.value.trim().toLowerCase());
            }else {
                leadsUserData[dataset.attribute] = input.value;
            }
        }else if(dataset.attributeAddress && (typeof dataset.attributeAddress === 'string')){
            if(!leadsUserData.address) {
                leadsUserData.address = {};
            }

            if (dataset.attributeAddress.includes('sha256_')) {
                leadsUserData.address[dataset.attributeAddress] = toHash(input.value.trim().toLowerCase());
            }else {
                leadsUserData.address[dataset.attributeAddress] = input.value;
            }
        }
    });

    return leadsUserData;
}